import React from 'react';
import {
  Dimmer,
  Form,
  Loader,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import OutilTable from '../OutilTable';
import type { OutilTableRowProps } from '../OutilTableRow';

// TODO: Utiliser les datas
const getRowData = (): OutilTableRowProps[] => ([
  {
    positive: false,
    solde: 200508.54,
    title: "Restes à réaliser en dépense d'investissement",
  },
  {
    positive: true,
    solde: 67300.23,
    title: "Restes à réaliser en recette d'investissement",
  },
  {
    positive: false,
    solde: -600,
    title: 'Restes à réaliser en dépense de fonctionnement',
  },
  {
    positive: true,
    solde: 0,
    title: 'Restes à réaliser en recette de fonctionnement',
  },
]);

interface ResteARealiserProps {
  onValidate: (data?: unknown) => void;
}

const ResteARealiser = ({
  onValidate,
}: ResteARealiserProps) => {
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [loadingValidate, setLoadingValidate] = React.useState<boolean>(false);

  const handleFetchRestesARealiser = async () => {
    try {
      setLoadingData(false);
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des restes à réaliser'));
    }
  };

  const handleValidate = async () => {
    try {
      setLoadingValidate(true);
      onValidate?.();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la recopie des restes à réaliser'));
    } finally {
      setLoadingValidate(false);
    }
  };

  React.useEffect(() => {
    handleFetchRestesARealiser();
  }, []);

  return loadingData ? (
    <Dimmer active inverted>
      <Loader size="massive" />
    </Dimmer>
  ) : (
    <>
      <OutilTable data={getRowData()} />
      <Form>
        <Form.Button
          content="Reprendre les restes à réaliser"
          data-testid="confirmButton"
          disabled={loadingValidate}
          floated="right"
          onClick={handleValidate}
          positive
        />
      </Form>
    </>
  );
};

export default ResteARealiser;
