export function getSoldeClass(solde: number) {
  const soldeClass = ['solde'];
  if (solde > 0) {
    soldeClass.push('positive');
  } else if (solde < 0) {
    soldeClass.push('negative');
  }

  return soldeClass;
}

export default getSoldeClass;
