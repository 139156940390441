import { request } from '@jvs-group/jvs-mairistem-store';
import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import TYPE_MONTANT_REGLE_CALCUL from '../enums/TypeMontantRegleCalcul';
import type RegleCalcul from '../interfaces/regleCalcul';

export const REGLE_CALCUL_ALIAS = {
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL]: 'budget_total',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N1]: 'budget_total_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N2]: 'budget_total_n2',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF]: 'budget_primitif',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N1]: 'budget_primitif_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N2]: 'budget_primitif_n2',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE]: 'budget_supplementaire',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N1]: 'budget_supplementaire_n1',
  [TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N2]: 'budget_supplementaire_n2',
  [TYPE_MONTANT_REGLE_CALCUL.RESTES_A_REALISER_N1]: 'restes_a_realiser_n1',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES]: 'decisions_modificatives',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N1]: 'decisions_modificatives_n1',
  [TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N2]: 'decisions_modificatives_n2',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE]: 'realise',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE_N1]: 'realise_n1',
  [TYPE_MONTANT_REGLE_CALCUL.REALISE_N2]: 'realise_n2',
};

export const getTypeMontantText = (typeMontant: number, anneeExercice: number): string => {
  let text = '';
  let minus = 0;

  switch (typeMontant) {
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL:
      text = 'Budget total';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N1:
      text = 'Budget total';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_TOTAL_N2:
      text = 'Budget total';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF:
      text = 'Budget primitif';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N1:
      text = 'Budget primitif';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_PRIMITIF_N2:
      text = 'Budget primitif';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE:
      text = 'Budget supplémentaire';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N1:
      text = 'Budget supplémentaire';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.BUDGET_SUPPLEMENTAIRE_N2:
      text = 'Budget supplémentaire';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.RESTES_A_REALISER_N1:
      text = 'Restes à réaliser';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES:
      text = 'Décisions modificatives';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N1:
      text = 'Décisions modificatives';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.DECISIONS_MODIFICATIVES_N2:
      text = 'Décisions modificatives';
      minus = 2;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE:
      text = 'Réalisé';
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE_N1:
      text = 'Réalisé';
      minus = 1;
      break;
    case TYPE_MONTANT_REGLE_CALCUL.REALISE_N2:
      text = 'Réalisé';
      minus = 2;
      break;
    default:
      break;
  }

  return anneeExercice ? `${text} ${anneeExercice - minus}` : `${text} N${minus === 0 ? '' : `-${minus}`}`;
};

export const getReglesCalcul = async (filters?: object) => {
  const params = new URLSearchParams();

  Object.entries(filters).forEach(([key, value]) => {
    params.append(key, value);
  });

  return request.get<any, TemplateRequestGet<RegleCalcul>>(
    `/api/finances/simulation/reglesCalcul?${params.toString()}`,
  );
};

export const createRegleCalcul = (regleCalcul: RegleCalcul) => request.post<RegleCalcul, RegleCalcul>(
  '/api/finances/simulation/reglesCalcul',
  regleCalcul,
);

export const deleteRegleCalcul = (identifiant: number) => request.delete<unknown, unknown>(
  `/api/finances/simulation/reglesCalcul/${identifiant}`,
);

export const updateRegleCalcul = (regleCalcul: RegleCalcul) => request.put<RegleCalcul, RegleCalcul>(
  `/api/finances/simulation/reglesCalcul/${regleCalcul.identifiant}`,
  regleCalcul,
);
