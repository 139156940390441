import React from 'react';
import {
  Accordion,
  Form,
  Icon,
  Input,
  Menu,
  Tab,
} from '@jvs-group/jvs-mairistem-composants';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { OptionsSelectionAvancees, typeNumber, typeString } from '@jvs-group/jvs-mairistem-liste';
import type BaseFeuilleSaisie from '../classes/FeuilleSaisie/BaseFeuilleSaisie';
import ImputationTable from '../../Simulation/components/ImputationTable';
import { fetchData } from '../utils/imputation';
import TreeViewFooter from './TreeViewFooter';
import type Simulation from '../../Simulation/interfaces/simulation';
import TreeViewPlaceholder from './TreeViewPlaceholder';
import { addFeuilleSaisie, getFeuillesSaisies } from '../utils/feuilleSaisie';
import FeuilleSaisieFactory from '../classes/FeuilleSaisie/FeuilleSaisieFactory';
import TreeViewFeuilleSaisie from './TreeViewFeuilleSaisie';
import PeriodeDropdown from './PeriodeDropdown';
import TypePeriode from '../../Simulation/enums/typePeriode';
import UserRole from '../../../constants/userRole';
import FEUILLES_SAISIES from '../constants/feuillesSaisies';
import FeuilleSaisieCode from '../constants/feuilleSaisieCode';
import 'rsuite-table/lib/less/index.less';
import './TreeView.less';

interface TreeViewProps {
  onChangeMontant: any;
  onChangePeriode: (periode: TypePeriode) => void;
  onRefresh: () => Promise<void>;
  simulation: Simulation;
  userRole?: UserRole;
}

const VISIBLE_PANE_COUNT = 9;

const options = [
  {
    'data-testid': 'optionToutesLignes',
    key: 0,
    text: 'Afficher toutes les lignes',
    value: 0,
  },
  {
    'data-testid': 'optionLignesAvecMontant',
    key: 1,
    text: 'Afficher uniquement les lignes avec montant',
    value: 1,
  },
  {
    'data-testid': 'optionLignesSaisies',
    key: 2,
    text: 'Afficher uniquement les lignes saisies',
    value: 2,
  },
];

const TreeView = ({
  onChangeMontant,
  onChangePeriode,
  onRefresh,
  simulation,
  userRole = UserRole.NORMAL,
}: TreeViewProps) => {
  const [feuillesSaisies, setFeuillesSaisies] = React.useState<BaseFeuilleSaisie[]>();
  const [feuilleSaisie, setFeuilleSaisie] = React.useState<BaseFeuilleSaisie>();
  const [data, setData] = React.useState([]);
  const [indexPaneFirstNode, setIndexPaneFirstNode] = React.useState(0);
  const [indexPaneVisible, setIndexPaneVisible] = React.useState(0);
  const [loadingPane, setLoadingPane] = React.useState(false);
  const [filters, setFilters] = React.useState<any>();
  const [feuilleTabLoading, setFeuilleTabLoading] = React.useState<boolean>(false);

  const fetchFeuillesSaisies = async () => {
    const listFeuille: BaseFeuilleSaisie[] = [];
    let feuilles = await getFeuillesSaisies();

    if (feuilles.length === 0) {
      feuilles = await Promise.all([
        addFeuilleSaisie(FeuilleSaisieCode.FONCTIONNEMENT, 1),
        addFeuilleSaisie(FeuilleSaisieCode.INVESTISSEMENT_PAR_OPERATION, 2),
        addFeuilleSaisie(FeuilleSaisieCode.OPERATION_ORDRE_BUDGETAIRE, 3),
      ]);
    }

    feuilles?.forEach((feuille) => {
      listFeuille.push(FeuilleSaisieFactory.newInstance(feuille, simulation?.exercice));
    });
    setFeuillesSaisies(listFeuille);
    setFeuilleSaisie(listFeuille[0]);
  };

  React.useEffect(() => {
    if (simulation?.exercice) fetchFeuillesSaisies();
  }, [simulation?.exercice]);

  React.useEffect(() => {
    if (simulation?.typePeriode) {
      setIndexPaneVisible(0);
      setFilters((old) => ({
        ...old,
        typePeriode: simulation.typePeriode,
      }));
    }
  }, [simulation?.typePeriode]);

  const getTabFilter = (data, activeIndex: number, feuille: BaseFeuilleSaisie) => {
    if (!feuille) {
      return null;
    }

    const baseTypeRegroupement = feuille.nodes[0][0];
    return {
      [baseTypeRegroupement.code]: data?.[activeIndex]?.[baseTypeRegroupement.codeColumnName] ?? null,
    };
  };

  const loadTabData = React.useCallback(async (data, activeIndex: number, feuille: BaseFeuilleSaisie) => {
    if (data[activeIndex].children.length > 0) {
      return;
    }

    const newData = [...data];
    // recuperation des données du treeview
    newData[activeIndex].children = await fetchData(
      simulation,
      feuille,
      feuille.nodes[1],
      {
        ...getTabFilter(data, activeIndex, feuille),
        ...filters,
      },
    );

    setData(newData);
  }, [simulation?.identifiant, filters]);

  const fetchFeuilleData = React.useCallback(async (index) => {
    if (!_.isNil(feuilleSaisie) && !_.isNil(simulation?.identifiant)) {
      // recuperation du premier noeud en tab
      const newData = await fetchData(simulation, feuilleSaisie, feuilleSaisie.nodes[0], filters);
      if (newData?.length === 0) {
        toast.error('Aucune ligne budgétaire répond à votre sélection');
      }
      loadTabData(newData, index, feuilleSaisie);
    }
  }, [simulation?.identifiant, feuilleSaisie, filters]);

  // Handler qui va mettre a jour les donnée de la treeview d'une tab
  const handleTabDataChange = (tabData) => {
    const newData = [...data];
    newData[indexPaneVisible + indexPaneFirstNode].children = tabData;
    setData(newData);
  };

  const handleFeuilleChange = React.useCallback(async () => {
    setLoadingPane(true);
    await fetchFeuilleData(0);
    setLoadingPane(false);
  }, [fetchFeuilleData]);

  // Au changement de ma feuille de saisie, je charge les données
  React.useEffect(() => {
    setData([]);
    setIndexPaneFirstNode(0);
    handleFeuilleChange();
  }, [handleFeuilleChange, feuilleSaisie]);

  const handleTabFirstNodeChange = async (e, d) => {
    if (d.activeIndex === indexPaneFirstNode) {
      return;
    }

    setLoadingPane(true);
    setIndexPaneFirstNode(d.activeIndex);
    await loadTabData(data, indexPaneVisible + d.activeIndex, feuilleSaisie);
    setLoadingPane(false);
  };

  const handleTabScrollPrevious = (e) => {
    e.stopPropagation();
    const nextIndexVisible = indexPaneVisible - VISIBLE_PANE_COUNT;
    setIndexPaneVisible(nextIndexVisible);
    loadTabData(data, nextIndexVisible, feuilleSaisie);
    setIndexPaneFirstNode(0);
  };

  const handleTabScrollNext = (e) => {
    e.stopPropagation();
    const nextIndexVisible = indexPaneVisible + VISIBLE_PANE_COUNT;
    setIndexPaneVisible(nextIndexVisible);
    loadTabData(data, nextIndexVisible, feuilleSaisie);
    setIndexPaneFirstNode(0);
  };

  const renderFirstNodePanes = React.useCallback(() => {
    if (data?.length === 0 || !feuilleSaisie) {
      return null;
    }

    const node = feuilleSaisie.nodes[0][0];
    const panes = [];
    const end = Math.min((indexPaneVisible + VISIBLE_PANE_COUNT - 1), (data.length - 1));
    for (let i = indexPaneVisible; i <= end; i++) {
      const currentData = data[i];
      const key = node.getKey(currentData);

      panes.push({
        menuItem: (
          <Menu.Item
            className="tabHeader"
            data-testid={`firstNode${key}`}
            key={i + key}
          >
            {(i === indexPaneVisible && indexPaneVisible > 0) && (
              <Icon
                data-testid="previousNode"
                name="arrow circle left"
                link
                className="tab-arrow"
                onClick={handleTabScrollPrevious}
              />
            )}

            <div className="content" title={node.getLibelle(data[i], feuilleSaisie)}>
              {node.getTabLibelle(currentData)}
            </div>

            {(i === (end) && end !== (data.length - 1)) && (
              <Icon
                data-testid="nextNode"
                name="arrow circle right"
                link
                className="tab-arrow end"
                onClick={handleTabScrollNext}
              />
            ) }
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane key={i + key}>
            <ImputationTable
              data={currentData.children}
              key={key}
              feuilleSaisie={feuilleSaisie}
              handleDataChange={handleTabDataChange}
              filters={{
                ...getTabFilter(data, indexPaneVisible + indexPaneFirstNode, feuilleSaisie),
                ...filters,
              }}
              onChangeMontant={onChangeMontant}
              simulation={simulation}
              userRole={userRole}
              onRefresh={onRefresh}
            />
            {
              feuilleSaisie?.isFooterVisible() && (
                <TreeViewFooter
                  data={currentData}
                  typeRegroupement={feuilleSaisie.nodes[1][0]}
                  feuilleSaisie={feuilleSaisie}
                  simulation={simulation}
                />
              )
            }
          </Tab.Pane>
        ),
      });
    }
    return panes;
  }, [data, feuilleSaisie, filters, indexPaneVisible, simulation?.typePeriode]);

  const handleTabFeuilleChange = async (e, { code }) => {
    if (code === feuilleSaisie.code) {
      return;
    }
    const feuille = feuillesSaisies.find((feuille) => feuille.code === code);
    setData([]);
    setIndexPaneVisible(0);
    setIndexPaneFirstNode(0);
    setFeuilleSaisie(feuille);
  };

  const handleFullTextSearchChange = (e, { value }) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({ ...old, q: value }));
  };

  const handleSearchMontant = (e, { value }: { value: string }) => {
    setIndexPaneVisible(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setFilters(({ montant, ...rest }) => ({
      ...rest,
      ...(value && { montant: value }),
    }));
  };

  const handleOptionChange = (e, { value }) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({ ...old, showOnly: value }));
  };

  const handleFeuilleSelect = async (f: BaseFeuilleSaisie) => {
    setFeuilleTabLoading(true);
    setIndexPaneVisible(0);
    setIndexPaneFirstNode(0);
    try {
      const newFeuille = await addFeuilleSaisie(f.code, feuillesSaisies.length);

      const feuille = FeuilleSaisieFactory.newInstance(newFeuille, simulation?.exercice);
      setFeuillesSaisies((old) => [...old, feuille]);
      setFeuilleSaisie(feuille);
    } catch {
      toast.error("Erreur lors de l'ajout de la feuille de saisie");
    } finally {
      setFeuilleTabLoading(false);
    }
  };

  const handleFiltresAvancesChange = (e, filtresAvances) => {
    setIndexPaneVisible(0);
    setFilters((old) => ({
      ...old,
      filtresAvances: JSON.stringify(filtresAvances.map(({
        id, value, type, condition,
      }) => ({
        id,
        value,
        type,
        condition,
      }))),
    }));
  };

  const handleChangePeriode = (typePeriode: TypePeriode) => onChangePeriode?.(typePeriode);

  const renderFiltresAvancee = () => {
    const codes = [
      {
        id: 'cha_code',
        libelle: 'Code chapitre',
        type: typeString,
      },
      {
        id: 'art_code',
        libelle: 'Code article',
        type: typeString,
      },
      {
        id: 'ope_code',
        libelle: 'Code opération',
        type: typeString,
      },
    ];

    const libelles = [
      {
        id: 'cha_libelle',
        libelle: 'Libellé chapitre',
        type: typeString,
      },
      {
        id: 'art_libelle',
        libelle: 'Libellé article',
        type: typeString,
      },
      {
        id: 'ope_libelle',
        libelle: 'Libellé opération',
        type: typeString,
      },
    ];

    if ((simulation?.exercice?.gestionVentilation && !simulation?.exercice?.venExec)) {
      codes.push(
        {
          id: 'ven_code',
          libelle: 'Code ventilation',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'ven_libelle',
          libelle: 'Libellé ventilation',
          type: typeString,
        },
      );
    }

    if (simulation?.exercice?.gestionAnalytique && !simulation?.exercice?.anaExec) {
      codes.push(
        {
          id: 'ana_code',
          libelle: 'Code analytique',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'ana_libelle',
          libelle: 'Libellé analytique',
          type: typeString,
        },
      );
    }

    if ((simulation?.exercice?.gestionFonctionnelle)) {
      codes.push(
        {
          id: 'fon_code',
          libelle: 'Code fonction',
          type: typeString,
        },
      );

      libelles.push(
        {
          id: 'fon_libelle',
          libelle: 'Libellé fonction',
          type: typeString,
        },
      );
    }
    const items = [
      {
        id: 'cha_section',
        libelle: 'Section',
        type: typeString,
        choices: [
          {
            key: 'I',
            text: 'Investissement',
            value: 'I',
          },
          {
            key: 'F',
            text: 'Fonctionnement',
            value: 'F',
          },
        ],
      },
      {
        id: 'imp_sens',
        libelle: 'Sens',
        type: typeString,
        choices: [
          {
            key: 'D',
            text: 'Dépense',
            value: 'D',
          },
          {
            key: 'R',
            text: 'Recette',
            value: 'R',
          },
        ],
      },
      ...codes,
      ...libelles,
      {
        id: 'mtsi_commentaire',
        libelle: 'Commentaire',
        type: typeString,
      },
      {
        id: 'imsi_prop',
        libelle: 'Proposé',
        type: typeNumber,
      },
      {
        id: 'imsi_vote',
        libelle: 'Voté',
        type: typeNumber,
      },
      {
        id: 'imsi_rep',
        libelle: 'Reporté',
        type: typeNumber,
      },
    ];

    return (
      <div>
        <OptionsSelectionAvancees
        // @ts-expect-error
          basic
          eventsEnabled={false}
          items={items}
          key="filters"
          popper={{
            class: 'filtresAvances',
          }}
          sortable={false}
          onSave={handleFiltresAvancesChange}
        />
      </div>
    );
  };

  const getPlaceholderRechercheMontant = () => {
    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        return 'Rechercher un montant demandé';
      case TypePeriode.PROPOSE:
        return 'Rechercher un montant proposé';
      case TypePeriode.VOTE:
        return 'Rechercher un montant voté';
      default:
        return 'Rechercher un montant';
    }
  };

  const handleKeyDownDisableEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <Accordion className="fiche-panel-header segment">
      <Accordion.Content active>
        <Menu
          className="menuFeuilleSaisie"
          pointing
          secondary
        >
          { feuillesSaisies?.map((feuille) => (
            <Menu.Item
              active={feuille.code === feuilleSaisie.code}
              code={feuille.code}
              data-testid={`feuilleSaisieMenuItem${feuille.code}`}
              content={feuille.libelle}
              onClick={handleTabFeuilleChange}
            />
          ))}
          {
            (userRole === UserRole.ADMIN
              && feuillesSaisies?.length < 8
              && FEUILLES_SAISIES.length !== feuillesSaisies.length
            ) && (
              <TreeViewFeuilleSaisie
                feuillesSaisies={feuillesSaisies}
                loading={feuilleTabLoading}
                onSelect={handleFeuilleSelect}
              />
            )
          }
        </Menu>
        <Form>
          <Form.Group className="treeViewBar">

            {simulation?.typePeriode !== TypePeriode.CA_CFU && (
              <Form.Field
                control={PeriodeDropdown}
                /* @ts-expect-error onChange */
                onChange={handleChangePeriode}
                value={simulation?.typePeriode}
                width={3}
              />
            )}

            <Form.Field
              // @ts-expect-error
              control={Input}
              debounceTime={1000}
              input={{ 'data-testid': 'fullTextSearchInput' }}
              onChange={handleFullTextSearchChange}
              value={filters?.q}
              onKeyDown={handleKeyDownDisableEnter}
              placeholder="Rechercher en saisissant un libellé, un commentaire ..."
              width={simulation?.typePeriode === TypePeriode.CA_CFU ? 9 : 7}
            />

            <Form.Field
              // @ts-expect-error
              control={Input}
              debounceTime={1000}
              input={{ 'data-testid': 'montantSearchInput', type: 'number' }}
              onChange={handleSearchMontant}
              onKeyDown={handleKeyDownDisableEnter}
              placeholder={getPlaceholderRechercheMontant()}
              value={filters?.montant}
              width={4}
            />

            <Form.Field>
              {renderFiltresAvancee()}
            </Form.Field>

            <Form.Select
              data-testid="optionsDropdown"
              onChange={handleOptionChange}
              options={options}
              defaultValue={0}
              width={3}
            />
          </Form.Group>
        </Form>
        {loadingPane
          ? <TreeViewPlaceholder />
          : (
            <Tab
              activeIndex={indexPaneFirstNode}
              menu={{ pointing: true, secondary: true }}
              onTabChange={handleTabFirstNodeChange}
              panes={renderFirstNodePanes()}
            />
          )}
      </Accordion.Content>
    </Accordion>
  );
};

export default TreeView;
