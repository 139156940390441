import React from 'react';
import {
  Combobox,
  Form,
  Grid,
  type CheckboxProps,
} from '@jvs-group/jvs-mairistem-composants';
import CalculPropositionModalCheckboxInput from './CalculPropositionModalCheckboxInput';
import {
  TYPE_ARRONDI_REGLE_CALCUL_OPTIONS,
  type TypeArrondiRegleCalcul,
} from '../../../Simulation/enums/typeArrondiRegleCalcul';
import type { MajorationMinorationValues } from './CalculPropositionModal';

interface CalculPropositionModalOptionsProps {
  majorationMinorationValues: MajorationMinorationValues;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>, value: number) => void;
  onChangeTypeArrondi: (e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number }) => void;
  typeArrondi: TypeArrondiRegleCalcul;
}

const CalculPropositionModalOptions = ({
  majorationMinorationValues,
  onChangeInput,
  onChangeTypeArrondi,
  typeArrondi,
}: CalculPropositionModalOptionsProps) => {
  const [checkboxsChecked, setCheckboxChecked] = React.useState({ forfaitaire: false, pourcentage: false });

  const handleChangeCheckbox = (e: React.MouseEvent<HTMLLabelElement>, { checked, name }: CheckboxProps) => {
    setCheckboxChecked((old) => ({
      ...old,
      [name]: checked,
    }));

    // Remise à zéro de la valeur si unCheck
    if (!checked) onChangeInput({ currentTarget: { name } } as React.ChangeEvent<HTMLInputElement>, 0);
  };

  React.useEffect(() => {
    setCheckboxChecked({
      forfaitaire: majorationMinorationValues.forfaitaire !== 0,
      pourcentage: majorationMinorationValues.pourcentage !== 0,
    });
  }, [majorationMinorationValues]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Form.Field
            // @ts-expect-error
            checkbox={{
              checked: checkboxsChecked.pourcentage,
              label: 'exprimée en pourcentage',
              name: 'pourcentage',
              onChange: handleChangeCheckbox,
            }}
            control={CalculPropositionModalCheckboxInput}
            currency={{
              disabled: !checkboxsChecked.pourcentage,
              icon: 'percent',
              name: 'pourcentage',
              onChange: onChangeInput,
              value: majorationMinorationValues?.pourcentage,
            }}
            label="Application d'une majoration / minoration"
            testid="majorationMinorationPourcentage"
          />
          <Form.Field
            // @ts-expect-error
            checkbox={{
              checked: checkboxsChecked.forfaitaire,
              label: 'forfaitaire',
              name: 'forfaitaire',
              onChange: handleChangeCheckbox,
            }}
            control={CalculPropositionModalCheckboxInput}
            currency={{
              disabled: !checkboxsChecked.forfaitaire,
              icon: 'eur',
              name: 'forfaitaire',
              onChange: onChangeInput,
              value: majorationMinorationValues?.forfaitaire,
            }}
            testid="majorationMinorationForfaitaire"
          />
        </Grid.Column>
        <Grid.Column>
          <Form.Field
            control={Combobox}
            data-testid="typeArrondiCombobox"
            label="Arrondi du résultat"
            // @ts-expect-error
            menuPortalTarget={document.body}
            name="typeArrondi"
            onChange={onChangeTypeArrondi}
            options={TYPE_ARRONDI_REGLE_CALCUL_OPTIONS}
            selection
            value={typeArrondi}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default CalculPropositionModalOptions;
