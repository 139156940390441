import React from 'react';
import {
  Button,
  Form,
  Modal,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { differenceBy } from 'lodash';
import { updateRegleCalcul } from '../../../../Simulation/utils/regleCalcul';
import type { RegleCalculModalActionsProps } from './RegleCalculModal';
import CalculPropositionModalPopupContent
  from '../../../../Outils/components/CalculProposition/CalculPropositionModalPopupContent';
import CalculPropositionModalOptions
  from '../../../../Outils/components/CalculProposition/CalculPropositionModalOptions';
import { TypeCalculRegleCalcul } from '../../../../Simulation/enums/TypeCalculRegleCalcul';
import type RegleCalcul from '../../../../Simulation/interfaces/regleCalcul';
import type RegleCalculModalErrors from '../interfaces/RegleCalculModalErrors';

const RegleCalculModalEdit = ({
  onClose,
  onValidate,
  open,
  regleCalcul,
}: RegleCalculModalActionsProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<RegleCalculModalErrors>({
    leftLignes: false,
    libelle: false,
    rightLignes: false,
  });
  const [editedRegleCalcul, setEditedRegleCalcul] = React.useState<RegleCalcul>(regleCalcul);

  const isFormError = (): boolean => {
    const isLeftLignesError = !editedRegleCalcul.lignes.filter((ligne) => !ligne.minus).length;
    const isLibelleError = !editedRegleCalcul.libelle;
    const isRightLignesError = !editedRegleCalcul.lignes.filter((ligne) => ligne.minus).length
      && editedRegleCalcul.typeCalcul === TypeCalculRegleCalcul.SOUSTRACTION;

    setErrors({
      leftLignes: isLeftLignesError,
      libelle: isLibelleError,
      rightLignes: isRightLignesError,
    });

    return isLeftLignesError || isLibelleError || isRightLignesError;
  };

  const handleChangeLibelle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    { name, value }: HTMLInputElement | HTMLSelectElement,
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
    }));

    setErrors((old) => ({
      ...old,
      libelle: (value ? false : old.libelle),
    }));
  };

  const handleChangeMajorationMinoration = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [currentTarget.name]: value,
    }));
  };

  const handleChangeTypeArrondi = (
    e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number },
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
    }));
  };

  const handleChangeTypeCalcul = (
    e: React.ChangeEvent<HTMLSelectElement>,
    { name, value }: { name: string, value: number },
  ) => {
    setEditedRegleCalcul((old) => ({
      ...old,
      [name]: value,
      lignes: [],
      deletedLignes: regleCalcul.lignes.map((regle) => regle.identifiant),
    }));
  };

  const handleChangeTypeMontant = (
    e: React.MouseEvent<HTMLSelectElement>,
    { minus, value }: { minus: boolean, value: number[]},
  ) => {
    const errorName = minus ? 'rightLignes' : 'leftLignes';

    setErrors((old) => ({
      ...old,
      [errorName]: (value.length ? false : old[errorName]),
    }));

    const allTypesMontant = editedRegleCalcul.lignes.map((ligne) => ligne.typeMontant);
    const newLignes = [
      // Je récupère les lignes que d'un côté.
      ...editedRegleCalcul.lignes.filter((ligne) => ligne.minus !== minus),
      // Je boucle sur ma liste de valeur
      ...value.map((typeMontant) => {
        // et si le typeMontant se trouve déjà dans mes lignes.
        if (allTypesMontant.includes(typeMontant)) {
          // J'ajoute celui des lignes
          return editedRegleCalcul.lignes.find((ligne) => ligne.typeMontant === typeMontant);
        }
        // Sinon, je l'ajoute
        return ({
          minus,
          typeMontant,
        });
      }),
    ];

    setEditedRegleCalcul((old) => ({
      ...old,
      lignes: newLignes,
      deletedLignes: [
        ...old.deletedLignes,
        ...differenceBy(
          old.lignes,
          newLignes,
          (ligne) => ligne.typeMontant,
        )
          .filter((ligne) => ligne?.identifiant)
          .map((ligne) => ligne.identifiant),
      ],
    }));
  };

  const handleMount = () => setEditedRegleCalcul(regleCalcul);

  const handleUpdate = async () => {
    if (isFormError()) return;

    try {
      setLoading(true);
      onValidate({
        ...await updateRegleCalcul(editedRegleCalcul),
        deletedLignes: [],
      });
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la modification de la règle de calcul'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      onMount={handleMount}
      open={open}
    >
      <Modal.Header content={`Modification de la règle de calcul: ${regleCalcul.libelle}`} />
      <Modal.Content>
        <Form>
          <Form.Input
            input={{ 'data-testid': 'regleCalculLibelleInput' }}
            label="Libellé de la règle de calcul"
            name="libelle"
            error={errors.libelle && 'Le champ est obligatoire'}
            onChange={handleChangeLibelle}
            required
            value={editedRegleCalcul.libelle}
          />
          Formulation
          <Segment>
            <CalculPropositionModalPopupContent
              errors={errors}
              lignesRegleCalcul={editedRegleCalcul.lignes}
              onChangeTypeCalcul={handleChangeTypeCalcul}
              onChangeTypeMontant={handleChangeTypeMontant}
              typeCalcul={editedRegleCalcul.typeCalcul}
            />
          </Segment>
          Options par défaut
          <Segment>
            <CalculPropositionModalOptions
              majorationMinorationValues={{
                forfaitaire: editedRegleCalcul.forfaitaire,
                pourcentage: editedRegleCalcul.pourcentage,
              }}
              onChangeInput={handleChangeMajorationMinoration}
              onChangeTypeArrondi={handleChangeTypeArrondi}
              typeArrondi={editedRegleCalcul.typeArrondi}
            />
          </Segment>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          icon="x"
          onClick={onClose}
          size="tiny"
        />
        <Button
          content="Modifier"
          data-testid="editButton"
          loading={loading}
          onClick={handleUpdate}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default RegleCalculModalEdit;
