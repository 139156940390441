import React from 'react';
import Panel from './Panel';
import EpargneNette from '../components/EpargneNette';

class EpargneNettePanel extends Panel {
  constructor() {
    super();
    this.code = 'EPAR_NET';
    this.icon = {
      iconSet: 'Lucide',
      name: 'PiggyBank',
    };
    this.size = 4;
    this.libelle = 'Epargne nette';
    // eslint-disable-next-line max-len
    this.tooltip = "Capacité d'autofinancement des opérations d'investissement après remboursement du capital de la dette.";
  }

  getComponent = function getComponent(
    data: any,
  ): React.ReactElement {
    return (
      <EpargneNette
        data={data}
      />
    );
  };
}

export default EpargneNettePanel;
