import FeuilleSaisieCode from '../../constants/feuilleSaisieCode';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';
import AnalytiqueTypeRegroupement from '../Regroupement/AnalytiqueTypeRegroupement';
import ChapitreTypeRegroupement from '../Regroupement/ChapitreTypeRegroupement';
import ImputationTypeRegroupement from '../Regroupement/ImputationTypeRegroupement';
import SensTypeRegroupement from '../Regroupement/SensTypeRegroupement';
import SectionTypeRegroupement from '../Regroupement/SectionTypeRegroupement';
import FonctionTypeRegroupement from '../Regroupement/FonctionTypeRegroupement';
import VentilationTypeRegroupement from '../Regroupement/VentilationTypeRegroupement';
import TypeRegroupement from '../../constants/typeRegroupement';
import ArticleTypeRegroupement from '../Regroupement/ArticleTypeRegroupement';

class OperationOrdreBudgetaireFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.code = FeuilleSaisieCode.OPERATION_ORDRE_BUDGETAIRE;
    this.libelle = "Opérations d'ordre budgétaires";
    this.defaultCodeFinArbo = TypeRegroupement.ARTICLE;
    this.nodes = [
      [new SectionTypeRegroupement()],
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'ArrowUpDown',
    };
  }
}

export default OperationOrdreBudgetaireFeuilleSaisie;
