import BalanceFonctionnementPanel from '../classes/BalanceFonctionnementPanel';
import BalanceInvestissementPanel from '../classes/BalanceInvestissementPanel';
import ChargePersonnelPanel from '../classes/ChargePersonnelPanel';
import EpargneBrutePanel from '../classes/EpargneBrutePanel';
import EpargneNettePanel from '../classes/EpargneNettePanel';

export const PANEL_LIST = [
  new BalanceFonctionnementPanel(),
  new BalanceInvestissementPanel(),
  new ChargePersonnelPanel(),
  new EpargneBrutePanel(),
  new EpargneNettePanel(),
];

export default PANEL_LIST;
