import React from 'react';
import {
  Accordion,
  Button,
  Grid,
  Icon,
} from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { isNil } from 'lodash';
import { getErrorMessage, PanelList } from '@jvs-group/jvs-mairistem-finances-utils';
import Panel from '../classes/Panel';
import PanelComponent from './Panel';
import { getPinnedPanel, deletePinnedPanel, addPinnedPanel } from '../utils/pinedPanel';
import { PANEL_LIST } from '../constants/panels';
import './Panels.less';
import type Simulation from '../../Simulation/interfaces/simulation';
import TypePeriode from '../../Simulation/enums/typePeriode';

interface PanelsProps {
  accordionActive: boolean;
  onToggleAccordion: () => void;
  simulation: Simulation,
}

const Panels = ({
  accordionActive = false,
  onToggleAccordion,
  simulation = null,
}: PanelsProps) => {
  const [panels, setPanels] = React.useState<Panel[]>([]);
  const [openPanelList, setOpenPanelList] = React.useState<boolean>(false);
  const [startIndexIndicateurs, setStartIndexIndicateurs] = React.useState<number>(0);

  const fetchPanels = async () => {
    try {
      const pinnedPanels = await getPinnedPanel(simulation?.exercice?.identifiantEntite);
      setPanels(PANEL_LIST.map((panel) => ({
        ...panel,
        isVisible: !isNil(pinnedPanels?.data?.find((p) => p.code === panel?.code)),
      })));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Impossible de récuperer les indicateurs'));
    }
  };

  React.useEffect(() => {
    if (simulation?.exercice?.identifiantEntite) fetchPanels();
  }, [simulation?.exercice?.identifiantEntite]);

  const handleRemovePanel = async (code: string) => {
    try {
      await deletePinnedPanel(code, simulation?.exercice?.identifiantEntite);
      setPanels(
        panels?.map((p) => ({
          ...p,
          isVisible: p.code === code ? false : p.isVisible,
        })),
      );
    } catch (e) {
      toast.error(getErrorMessage(e, "Impossible de supprimer l'indicateur"));
    }
  };

  const handlePanelSelect = async (code: string) => {
    try {
      await addPinnedPanel(code, simulation?.exercice?.identifiantEntite);
      setPanels(
        panels.map(((p) => ({
          ...p,
          isVisible: p.code === code ? true : p.isVisible,
        }))),
      );

      setOpenPanelList(false);
    } catch (e) {
      toast.error(getErrorMessage(e, "Impossible d'ajouter l'indicateur"));
    }
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const renderPanelList = () => {
    const remainingPanels = panels?.filter((p) => !p.isVisible);
    return (
      <span
        className="panelListContainer"
        data-testid="indicateursAddButton"
        onClick={handleStopPropagation}
        onKeyDown={handleStopPropagation}
        role="presentation"
      >
        <PanelList
          open={openPanelList}
          isSearchable
          handleOpen={setOpenPanelList}
          panels={remainingPanels}
          popupPosition="bottom right"
          onSelect={handlePanelSelect}
          popupTrigger={(
            <Button
              icon="add"
              disabled={!remainingPanels?.length}
              link={!!remainingPanels?.length}
              content="Ajouter un indicateur"
            />
            )}
        />
      </span>
    );
  };

  const handlePanelScrollStart = () => {
    setStartIndexIndicateurs((old) => old - 1);
  };

  const handlePanelScrollEnd = () => {
    setStartIndexIndicateurs((old) => old + 1);
  };

  const getPanelDataFromPeriode = () => {
    let data = null;
    switch (simulation?.typePeriode) {
      case TypePeriode.DEMANDE:
        data = simulation.panelData.demande;
        break;
      case TypePeriode.PROPOSE:
        data = simulation.panelData.prop;
        break;
      case TypePeriode.VOTE:
        data = simulation.panelData.vote;
        break;
      default:
    }

    return {
      ...data,
      ...simulation.panelData,
      anneeExercice: simulation?.exercice?.anneeExercice,
    };
  };

  const renderIndicateurs = () => {
    const indicateurs = [];
    const visiblePanels = panels?.filter((panel) => panel.isVisible);
    let remaningPanelSize = 0;

    for (let i: number = startIndexIndicateurs; i < visiblePanels.length; i++) {
      remaningPanelSize += Number(visiblePanels[i].size);
      indicateurs.push((
        <PanelComponent
          code={visiblePanels[i]?.code}
          icon={visiblePanels[i]?.icon}
          onRemove={() => handleRemovePanel(visiblePanels[i]?.code)}
          size={visiblePanels[i]?.size}
          tooltip={visiblePanels[i]?.tooltip}
        >
          {visiblePanels[i]?.getComponent(getPanelDataFromPeriode())}
        </PanelComponent>
      ));
    }

    return (
      <>
        {startIndexIndicateurs > 0 && (
        <Icon
          data-testid="previousIndicateur"
          name="arrow alternate circle left"
          className="start"
          link
          onClick={handlePanelScrollStart}
        />
        )}
        { indicateurs.map((indicateur) => indicateur)}
        {remaningPanelSize > 16 && (
        <Icon
          data-testid="nextIndicateur"
          name="arrow alternate circle right"
          className="end"
          link
          onClick={handlePanelScrollEnd}
        />
        )}
      </>
    );
  };

  return (
    <Accordion className="fiche-panel-header segment">
      <Accordion.Title
        active={accordionActive}
        data-testid="accordionIndicateurs"
        onClick={onToggleAccordion}
      >
        <Icon name="dropdown" />
        INDICATEURS CLÉS
        {accordionActive && renderPanelList()}
      </Accordion.Title>
      <Accordion.Content active={accordionActive} className="indicateurs">
        <Grid className="fullWidth" stretched>
          <Grid.Row className="panelRow">
            {renderIndicateurs()}
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default Panels;
