import React from 'react';
import { Segment } from '@jvs-group/jvs-mairistem-composants';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { getSimulation } from '../utils/simulation';
import type Simulation from '../interfaces/simulation';
import Panels from '../../Panels/components/Panels';
import TreeView from '../../FeuilleSaisie/components/TreeView';
import type { TreeRow } from '../../FeuilleSaisie/interfaces/treeRow';
import BoiteOutil from './BoiteOutil';
import TypePeriode from '../enums/typePeriode';
import type PanelsData from '../../Panels/interfaces/panelsData';
import StorageKey from '../../../constants/storage';
import SessionContext from '../../../components/Context/SessionContext';
import TypeBudget from '../enums/typeBudget';
import './ElaborationBudget.less';
import { propToVote } from '../../Outils/utils/outil';

enum AccordionName {
  BOITE_OUTIL = 'boiteOutil',
  PANELS = 'panels'
}

interface AccordionsActive {
  [AccordionName.BOITE_OUTIL]: boolean;
  [AccordionName.PANELS]: boolean;
}

const DEFAULT_ACCORDIONS_STATE: AccordionsActive = {
  [AccordionName.BOITE_OUTIL]: false,
  [AccordionName.PANELS]: false,
};

interface ElaborationBudgetProps {
  identifiantSimulation: number;
}

const ElaborationBudget = ({ identifiantSimulation }: ElaborationBudgetProps) => {
  const { userRole } = React.useContext(SessionContext);
  const [simulation, setSimulation] = React.useState<Simulation>(null);
  const [accordionsActive, setAccordionsActive] = React.useState<AccordionsActive>(
    JSON.parse(localStorage.getItem(StorageKey.ACCORDIONS)) ?? DEFAULT_ACCORDIONS_STATE,
  );

  const getDefaultPeriode = (simulation : Simulation) => {
    if (simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF) {
      return TypePeriode.CA_CFU;
    }

    return simulation?.vote ? TypePeriode.VOTE : TypePeriode.PROPOSE;
  };

  const fetchSimulation = async (identifiantSimulation: number, onlyPanelData: boolean = false) => {
    const s = await getSimulation(identifiantSimulation);

    if (onlyPanelData) {
      setSimulation((old) => ({
        ...old,
        panelData: s.panelData,
      }));
    } else {
      setSimulation({
        ...s,
        typePeriode: getDefaultPeriode(s),
      });
    }
  };

  const handleRefreshSimulation = (
    onlyPanelData: boolean = false,
  ) => fetchSimulation(Number(identifiantSimulation), onlyPanelData);

  // Récupération de l'identifiant de simulation dans l'url
  React.useEffect(() => {
    handleRefreshSimulation();
  }, []);

  const handleChangeImputation = (panelDataChange: PanelsData) => {
    const panelData : PanelsData = {
      prop: {},
      demande: {},
      vote: {},
      exercicePrecedent: {},
      exercicePrecedent2: {},
      ca: {},
    };
    Object.keys(simulation.panelData).forEach((typePeriode) => {
      Object.keys(simulation.panelData[typePeriode]).forEach((key) => {
        if (typeof simulation.panelData[typePeriode][key] === 'object'
          && simulation.panelData[typePeriode][key] !== null) {
          // on ne met pas a jour les annees precedentes
          panelData[typePeriode][key] = simulation.panelData[typePeriode][key];
        } else {
          // eslint-disable-next-line max-len
          panelData[typePeriode][key] = (simulation.panelData[typePeriode][key] ?? 0) + (panelDataChange?.[typePeriode]?.[key] ?? 0);
        }
      });
    });

    setSimulation((s) => ({
      ...s,
      panelData,
    }));
  };

  const handleChangeMontant = (differencesMontants: any, imputation: TreeRow) => {
    const panelDataChanges : PanelsData = { prop: {}, demande: {}, vote: {} };

    Object.keys(differencesMontants).forEach((typePeriode) => {
      if (imputation.imp_sens === 'D') {
        if (imputation.cha_section === 'I') {
          panelDataChanges[typePeriode].depenseInvestissement = differencesMontants[typePeriode];
        } else {
          panelDataChanges[typePeriode].depenseFonctionnement = differencesMontants[typePeriode];
          if (imputation.cha_nat_bud === 'R') {
            panelDataChanges[typePeriode].depenseReelFonctionnement = differencesMontants[typePeriode];
            panelDataChanges[typePeriode].epargneBrute = -differencesMontants[typePeriode];
          }
        }

        if (imputation.cha_code === '012') {
          panelDataChanges[typePeriode].montantChargePersonnel = differencesMontants[typePeriode];
        }

        if (imputation.cha_nat_bud === 'R'
          && imputation.art_code?.startsWith('1641')) {
          panelDataChanges[typePeriode].remboursementCapitalDette = differencesMontants[typePeriode];
        }
      } else if (imputation?.imp_sens === 'R') {
        if (imputation?.cha_section === 'I') {
          panelDataChanges[typePeriode].recetteInvestissement = differencesMontants[typePeriode];
        } else {
          panelDataChanges[typePeriode].recetteFonctionnement = differencesMontants[typePeriode];

          if (!imputation.art_code?.startsWith('002')
          && !imputation.art_code?.startsWith('775')
          && !simulation?.transfertCpta
          && simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
          && imputation.cha_nat_bud === 'R') {
            panelDataChanges[typePeriode].epargneBrute = differencesMontants[typePeriode];
          }
        }
      }
    });

    handleChangeImputation(panelDataChanges);
  };

  const handleChangePeriode = async (typePeriode: TypePeriode) => {
    let vote = simulation?.vote;
    if (typePeriode === TypePeriode.VOTE && !simulation?.vote) {
      try {
        await propToVote(simulation?.identifiant);
        vote = true;
      } catch (e) {
        toast.error(getErrorMessage(e, 'Erreur lors du vote'));
      }
    }

    setSimulation((old) => ({
      ...old,
      typePeriode,
      vote,
    }));
  };

  const handleToggleAccordion = (key: string) => {
    setAccordionsActive((old) => {
      const accordions = {
        ...old,
        [key]: !old[key],
      };

      localStorage.setItem(StorageKey.ACCORDIONS, JSON.stringify(accordions));
      return accordions;
    });
  };

  return (
    <Segment
      basic
      secondary
    >
      <BoiteOutil
        accordionActive={accordionsActive?.boiteOutil}
        onRefresh={handleRefreshSimulation}
        onToggleAccordion={() => handleToggleAccordion(AccordionName.BOITE_OUTIL)}
        simulation={simulation}
        userRole={userRole}
      />
      <Panels
        accordionActive={accordionsActive?.panels}
        onToggleAccordion={() => handleToggleAccordion(AccordionName.PANELS)}
        simulation={simulation}
      />

      <TreeView
        onChangeMontant={handleChangeMontant}
        onChangePeriode={handleChangePeriode}
        onRefresh={handleRefreshSimulation}
        simulation={simulation}
        userRole={userRole}
      />
    </Segment>
  );
};

export default ElaborationBudget;
