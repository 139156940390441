import React from 'react';
import BaseOutil from './BaseOutil';
import TransfertComptaModal from '../components/TransfertComptaModal';
import TypeBudget from '../../Simulation/enums/typeBudget';
import TypePeriode from '../../Simulation/enums/typePeriode';
import type UserRole from '../../../constants/userRole';
import type Simulation from '../../Simulation/interfaces/simulation';

const getTitle = (simulation: Simulation): string => {
  switch (simulation?.budget?.type) {
    case TypeBudget.BUDGET_PRIMITIF:
    case TypeBudget.BUDGET_SUPPLEMENTAIRE:
    case TypeBudget.DECISION_MODIFICATIVE:
      return simulation?.transfertCpta ? 'Annuler le caractère exécutoire' : 'Rendre exécutoire';
    case TypeBudget.COMPTE_ADMINISTRATIF:
    case TypeBudget.VIREMENT_INTERNE:
      return simulation?.transfertCpta ? 'Dévalider' : 'Valider';
    default:
      return simulation?.transfertCpta ? 'Ne plus rendre exécutoire' : 'Rendre exécutoire';
  }
};

class TransfertCompta extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'TRANS_CPTA';
    this.icon = {
      iconSet: 'Lucide',
      name: 'HandCoins',
    };
    this.title = getTitle(simulation);
  }

  isEnabled(userRole: UserRole, simulation: Simulation): boolean {
    return super.isEnabled(userRole, simulation) && simulation?.vote && simulation?.typePeriode === TypePeriode.VOTE;
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <TransfertComptaModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default TransfertCompta;
