import type { IconJVSProps } from '@jvs-group/jvs-mairistem-composants';
import TypeRegroupement from '../../constants/typeRegroupement';
import type FeuilleSaisie from '../../interfaces/feuilleSaisie';
import type BaseTypeRegroupement from '../Regroupement/BaseTypeRegroupement';
import SensTypeRegroupement from '../Regroupement/SensTypeRegroupement';
import TypeRegroupementFactory from '../Regroupement/TypeRegroupementFactory';

class BaseFeuilleSaisie {
  code: string;

  icon: IconJVSProps;

  nodes: BaseTypeRegroupement[][];

  libelle: string;

  isCreditReporteShown = false;

  chapitreArticleLie = false;

  defaultCodeFinArbo = null;

  isFooterVisible(): boolean {
    return this.nodes[0][0].constructor === SensTypeRegroupement
      || this.nodes[1][0].constructor === SensTypeRegroupement;
  }

  // qui vient de simulation.exercice
  setNoeuds(feuille: FeuilleSaisie, exercice: any): void {
    // Le 1er noeud n'est pas en base
    this.nodes = [this.nodes[0]];
    const nodeArbo = [];
    const nodeSansArbo = [];
    let isSansArbo = false;

    feuille?.noeuds?.forEach((noeud) => {
      // Si on est dans un cas : Ventilation/Analytique/Fonction et que la gestion associée n'est pas activée
      if ((noeud.code !== TypeRegroupement.VENTILATION || (exercice.gestionVentilation && !exercice.venExec))
        && (noeud.code !== TypeRegroupement.ANALYTIQUE || (exercice.gestionAnalytique && !exercice.anaExec))
        && (noeud.code !== TypeRegroupement.FONCTION || exercice.gestionFonctionnelle)
        && (noeud.code !== TypeRegroupement.ARTICLE || !feuille.chapitreArticleLie)
      ) {
        const nodesList = [];
        const typeRegroupement = TypeRegroupementFactory.newInstance(noeud.code);
        if (noeud.code === feuille.codeFinArbo) {
          isSansArbo = true;
        }

        if (noeud.code === TypeRegroupement.CHAPITRE && feuille.chapitreArticleLie) {
          nodesList.push([typeRegroupement, TypeRegroupementFactory.newInstance(TypeRegroupement.ARTICLE)]);
        } else {
          nodesList.push([typeRegroupement]);
        }

        if (isSansArbo) {
          nodeSansArbo.push(...nodesList);
        } else {
          nodeArbo.push(...nodesList);
        }
      }
    });

    this.nodes = [
      this.nodes[0],
      ...nodeArbo,
      [...nodeSansArbo.flat(), TypeRegroupementFactory.newInstance(TypeRegroupement.IMPUTATION)],
    ];
  }
}

export default BaseFeuilleSaisie;
