import BaseOutil from './BaseOutil';

class ControleCoherenceProposition extends BaseOutil {
  constructor() {
    super();
    this.code = 'CTRL_CHR_PROP';
    this.icon = {
      iconSet: 'Lucide',
      name: 'ListChecks',
    };
  }

  title = 'Contrôler la cohérence des propositions';

  isEnabled() { return false; }
}

export default ControleCoherenceProposition;
