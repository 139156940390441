import React from 'react';
import Panel from './Panel';
import BalanceFonctionnement from '../components/BalanceFonctionnement';

class BalanceFonctionnementPanel extends Panel {
  constructor() {
    super();
    this.code = 'BLC_FONC';
    this.icon = {
      iconSet: 'Lucide',
      name: 'BatteryCharging',
    };
    this.size = 4;
    this.libelle = 'Balance de fonctionnement';
  }

  getComponent = function getComponent(
    data: any,
  ): React.ReactElement {
    return (
      <BalanceFonctionnement
        data={data}
      />
    );
  };
}

export default BalanceFonctionnementPanel;
