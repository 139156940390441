import React from 'react';
import BaseOutil from './BaseOutil';
import type Simulation from '../../Simulation/interfaces/simulation';
import RecopiePropositionEnVoteModal from '../components/RecopiePropositionEnVoteModal';

class RecopiePropositionEnVote extends BaseOutil {
  constructor(simulation: Simulation) {
    super();
    this.code = 'RECOP_PROP_VOTE';
    this.icon = {
      iconSet: 'Lucide',
      name: 'Vote',
    };
    this.title = simulation?.vote
      ? 'Remettre à zéro les montants votés' : 'Recopier les propositions dans les montants votés';
  }

  renderModal(
    simulation: Simulation,
    onClose: () => void,
    onValidate: (data: unknown) => void,
    open: boolean,
  ) {
    return (
      <RecopiePropositionEnVoteModal
        onClose={onClose}
        onValidate={onValidate}
        open={open}
        simulation={simulation}
      />
    );
  }
}

export default RecopiePropositionEnVote;
