import React from 'react';
import type { IconJVSProps } from '@jvs-group/jvs-mairistem-composants';
import TypePeriode from '../../../Simulation/enums/typePeriode';
import type TypeRegroupement from '../../constants/typeRegroupement';
import { TreeRow } from '../../interfaces/treeRow';
import { getMontantImputation } from '../../utils/imputation';
import type BaseFeuilleSaisie from '../FeuilleSaisie/BaseFeuilleSaisie';

class BaseTypeRegroupement {
  code: TypeRegroupement;

  libelle: string;

  codeColumnName: string;

  libelleColumnName: string;

  icon: IconJVSProps;

  getKey(data : TreeRow): string {
    return this.code + data[this.codeColumnName];
  }

  getLibelle(data: TreeRow, _feuille: BaseFeuilleSaisie): string {
    return `${data[this.codeColumnName] ?? ''} - ${data[this.libelleColumnName] ?? ''}`;
  }

  getTabLibelle(data: TreeRow): JSX.Element {
    return (
      <>
        <div>
          {data[this.codeColumnName] ?? '' }
        </div>
        {data[this.libelleColumnName] ?? ''}
      </>
    );
  }

  getSolde(data: TreeRow) {
    return {
      libelle: 'TOTAL',
      ...(data?.children?.reduce((prev, next) => ({
        budget: prev.budget + next.budget,
        realise: prev.realise + next.realise,
        report: prev.report + next.report,
        rar: prev.rar + next.rar,
        montantDemande: prev.montantDemande + getMontantImputation(next, TypePeriode.DEMANDE),
        montantProp: prev.montantProp + getMontantImputation(next, TypePeriode.PROPOSE),
        montantVote: prev.montantVote + getMontantImputation(next, TypePeriode.VOTE),
      }), {
        budget: 0,
        realise: 0,
        montantDemande: 0,
        montantProp: 0,
        montantVote: 0,
        report: 0,
        rar: 0,
      })),
    };
  }
}

export default BaseTypeRegroupement;
