import { request } from '@jvs-group/jvs-mairistem-store';
import { isEmpty } from 'lodash';
import Simulation from '../interfaces/simulation';
import TypeBudget from '../enums/typeBudget';
import TypeReport from '../enums/typeReport';
import type BaseTypeRegroupement from '../../FeuilleSaisie/classes/Regroupement/BaseTypeRegroupement';
import type DetailCalcul from '../../Outils/interfaces/DetailCalcul';

export const getSimulation = (identifiant: number) => request.get<unknown, Simulation>(
  `/api/finances/simulation/${identifiant}`,
);

export const upsertMontant = (
  data: any,
) => request.put('/api/finances/simulation/montantSimulation/upsertByImputation', data);

export const shouldAddReport = (simulation: Simulation) => {
  let reportTypeBudget = null;
  switch (simulation.exercice.reportBpBs) {
    case TypeReport.BUDGET_PRIMITIF:
      reportTypeBudget = TypeBudget.BUDGET_PRIMITIF;
      break;
    case TypeReport.BUDGET_SUPPLEMENTAIRE:
      reportTypeBudget = TypeBudget.BUDGET_SUPPLEMENTAIRE;
      break;
    case TypeReport.DECISION_MODIFICATIVE:
      reportTypeBudget = TypeBudget.DECISION_MODIFICATIVE;
      break;
    default:
  }

  return simulation.budget.type === reportTypeBudget;
};

export const calculImputations = (
  identifiantSimulation: number,
  filters: any,
  data: {
    identifiantRegleCalcul: number
    pourcentageMajoration: number,
    montantForfaitaire: number,
    typeArrondi: number,
    codeFeuille: string,
  },
) => request.post(`/api/finances/simulation/${identifiantSimulation}/calculImputations`, { ...data, ...filters });

export const getDetailCalculImputations = (
  identifiantSimulation: number,
  filters: any,
  typesRegroupement: BaseTypeRegroupement[],
  data: {
    identifiantRegleCalcul: number
    pourcentageMajoration: number,
    montantForfaitaire: number,
    typeArrondi: number,
    codeFeuille: string,
  },
) : Promise<DetailCalcul> => {
  const params = new URLSearchParams();
  params.append('identifiantRegleCalcul', data.identifiantRegleCalcul?.toString());
  params.append('pourcentageMajoration', data.pourcentageMajoration?.toString());
  params.append('montantForfaitaire', data.montantForfaitaire?.toString());
  params.append('typeArrondi', data.typeArrondi?.toString());
  params.append('codeFeuille', data.codeFeuille);
  if (!isEmpty(filters)) {
    Object.entries(filters).forEach(([key, value]) => {
      params.append(key, value.toString());
    });
  }

  typesRegroupement.forEach((typeRegroupement) => {
    params.append('typesRegroupement[]', typeRegroupement.code);
  });

  return request.get(
    `/api/finances/simulation/${identifiantSimulation}/detailCalculImputations?${params.toString()}`,
  );
};
