import type { TemplateRequestGet } from '@jvs-group/jvs-mairistem-finances-utils';
import { request } from '@jvs-group/jvs-mairistem-store';
import StorageKey from '../../../constants/storage';

export const getExercice = async (identifiant: string) => {
  const exercice = await request.get<unknown, TemplateRequestGet<any>>(
    `/api/finances/comptabilite/parametrescomptabilites/exercices/${identifiant}`,
  ).then((d) => d.data);

  return exercice;
};

export const getCurrentExercice = () => JSON.parse(sessionStorage.getItem(StorageKey.EXERCICE));

export default getExercice;
