import React from 'react';
import { Table } from 'rsuite-table';
import { TreeRow } from '../interfaces/treeRow';
import BaseTypeRegroupement from '../classes/Regroupement/BaseTypeRegroupement';
import ROW_HEIGHT from '../constants/tableProps';
import BaseFeuilleSaisie from '../classes/FeuilleSaisie/BaseFeuilleSaisie';
import TypePeriode from '../../Simulation/enums/typePeriode';
import { renderMontantText } from '../../Simulation/utils/montant';
import { shouldAddReport } from '../../Simulation/utils/simulation';
import type Simulation from '../../Simulation/interfaces/simulation';
import 'rsuite-table/lib/less/index.less';
import './TreeViewFooter.less';

interface TreeViewFooterProps {
  simulation: Simulation,
  data: TreeRow;
  feuilleSaisie: BaseFeuilleSaisie;
  typeRegroupement: BaseTypeRegroupement;
}

const TreeViewFooter = ({
  simulation = null,
  data = null,
  feuilleSaisie = null,
  typeRegroupement = null,
}: TreeViewFooterProps) => (
  <Table
    key="totaux"
    className="treeViewFooter"
    height={ROW_HEIGHT}
    rowHeight={ROW_HEIGHT}
    data={[typeRegroupement.getSolde(data)]}
    showHeader={false}
    rowKey="libelle"
  >
    {({ Cell, Column, HeaderCell }) => (
      <>
        <Column
          flexGrow={5}
          fullText
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="libelle" />
        </Column>
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="budget">
            {(rowData) => renderMontantText(rowData.budget)}
          </Cell>
        </Column>
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="realise">
            {(rowData) => renderMontantText(rowData.realise)}
          </Cell>
        </Column>

        {(feuilleSaisie.isCreditReporteShown && shouldAddReport(simulation)) && (
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="report">
            {(rowData) => renderMontantText(rowData.report, true)}
          </Cell>
        </Column>
        )}

        {simulation?.typePeriode === TypePeriode.DEMANDE && (
          <Column
            align="right"
            flexGrow={1}
            verticalAlign="middle"
          >
            <HeaderCell>{' '}</HeaderCell>
            <Cell dataKey="montantDemande">
              {(rowData) => renderMontantText(rowData.montantDemande, true)}
            </Cell>
          </Column>
        )}

        {[TypePeriode.PROPOSE, TypePeriode.VOTE]?.includes(simulation?.typePeriode) && (
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="montantPropose">
            {(rowData) => renderMontantText(rowData.montantProp, simulation.typePeriode === TypePeriode.PROPOSE)}
          </Cell>
        </Column>
        )}

        {simulation?.typePeriode === TypePeriode.VOTE && (
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="montantVote">
            {(rowData) => renderMontantText(rowData.montantVote, true)}
          </Cell>
        </Column>
        )}

        {simulation?.typePeriode === TypePeriode.CA_CFU && (
        <Column
          align="right"
          flexGrow={1}
          verticalAlign="middle"
        >
          <HeaderCell>{' '}</HeaderCell>
          <Cell dataKey="montantCaCfu">
            {(rowData) => renderMontantText(rowData.rar, true)}
          </Cell>
        </Column>
        )}

        <Column flexGrow={2}>
          <HeaderCell>{' '}</HeaderCell>
          <Cell />
        </Column>

        <Column>
          <HeaderCell>{' '}</HeaderCell>
          <Cell />
        </Column>
      </>
    )}
  </Table>
);

export default TreeViewFooter;
