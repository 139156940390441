import { request } from '@jvs-group/jvs-mairistem-store';
import { isEmpty } from 'lodash';
import type Reports from '../interfaces/Reports';
import type ImputationEmprunt from '../interfaces/ImputationEmprunt';

export const recopieReport = (identifiant: number, values: object) => {
  const params = new URLSearchParams();

  if (values) {
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (!isEmpty(value)) params.append(key, value);
    });
  }

  return request.get<unknown, unknown>(
    `/api/finances/simulation/${identifiant}/recopieReport?${params.toString()}`,
  );
};

export const getReports = async (identifiant: number) => request.get<any, Reports>(
  `/api/finances/simulation/${identifiant}/getReports`,
);

export const calculVirementCredit = (identifiant: number) => request.get<unknown, unknown>(
  `/api/finances/simulation/${identifiant}/calculVirementSection`,
);

export const getVirementsCredit = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/getVirementsSection`,
);

export const transfertComptabilite = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/transfertComptabilite`,
);

export const annulationTransfertComptabilite = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/annulationTransfertComptabilite`,
);

export const propToVote = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/propToVote`,
);

export const annulationVote = async (identifiant: number) => request.get<any, any>(
  `/api/finances/simulation/${identifiant}/annulationVote`,
);

export const importEmprunt = (identifiant: number) => request.get<unknown, ImputationEmprunt[]>(
  `/api/finances/simulation/${identifiant}/importEmprunt`,
);

export const upsertMontants = (
  identifiantSimulation: number,
  montants: any,
) => request.put<unknown, unknown>(
  '/api/finances/simulation/montantSimulation',
  {
    montants,
    identifiantSimulation,
  },
);
